import cx from "classnames";
import React, { CSSProperties, useCallback, useRef } from "react";
import { KeyName, useKeyDown, useRouterChange } from "shared/lib";
import { useHandleResize } from "../../lib/use-handle-resize";
import { Drawer } from "../drawer/drawer";

import { ModalAction } from "./action";
import { ModalActions } from "./actions";
import { ModalBody } from "./body";
import { ModalFormItem } from "./form-item";
import { ModalHeader } from "./header";
import styles from "./modal.module.scss";
import { ModalSubtitle } from "./subtitle";
import { ModalTitle } from "./title";

export interface IModal {
  children?: React.ReactNode;
  onClose?: () => void;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
  width?: number;
}

export const Modal = (props: IModal) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const onClose = useCallback(() => props.onClose?.(), [props.onClose]);

  useKeyDown(KeyName.ESC, onClose);
  useRouterChange(onClose, [onClose]);

  const width = useHandleResize();
  if (width === 0) return null;

  if (width >= 600)
    return (
      <div className={cx(styles.modal, props.className)} style={props.style} id={props.htmlId} onMouseDown={onClose}>
        <div className={styles.wrapper} style={{ width: props.width }} onMouseDown={(e) => e.stopPropagation()}>
          {props.children}
        </div>
      </div>
    );

  return (
    <Drawer onDismiss={onClose} show={width < 600}>
      {props.children}
    </Drawer>
  );
};

Modal.defaultProps = {};

Modal.Action = ModalAction;
Modal.Actions = ModalActions;
Modal.Body = ModalBody;
Modal.Header = ModalHeader;
Modal.Subtitle = ModalSubtitle;
Modal.Title = ModalTitle;
Modal.FormItem = ModalFormItem;
