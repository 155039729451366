import { MenuLink as ReachMenuLink } from "../../lib/menu-button";
import cx from "classnames";
import Link from "next/link";
import React, { CSSProperties } from "react";
import styles from "./menu-item.module.scss";

export interface IMenuLink {
  children?: React.ReactNode;
  href: string;

  type?: "default" | "error";
  disabled?: boolean;

  prefix?: React.ReactNode;
  suffix?: React.ReactNode;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const MenuLink = (props: IMenuLink) => {
  return (
    <ReachMenuLink
      as={Link}
      href={props.href}
      className={cx(styles.menu_item, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      {props.prefix && <span className={styles.prefix}>{props.prefix}</span>}
      <span>{props.children}</span>
      {props.suffix && <span className={styles.suffix}>{props.suffix}</span>}
    </ReachMenuLink>
  );
};

MenuLink.defaultProps = {};
