import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./menu-divider.module.scss";

export interface IMenuDivider {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const MenuDivider = (props: IMenuDivider) => {
  return (
    <div className={cx(styles.menu_divider, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

MenuDivider.defaultProps = {};
