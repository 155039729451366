import Router from "next/router";
import pages from "pages/_router";
import React, { FormEvent, useState } from "react";
import { api } from "shared/api";
import { useApiMethod, UseModalProps } from "shared/lib";
import { Error, Input, LoadingDots, Modal } from "shared/ui";

export interface ICompanyCreateModal extends UseModalProps {}

export const CompanyCreateModal = (props: ICompanyCreateModal) => {
  const list = api.company.useCompanyList();
  const submit = useApiMethod(api.company.companyCreate);

  const [name, setName] = useState("");

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!name) return;
    if (name.length < 5) {
      submit.setError({ type: "manual", message: "Слишком короткое имя компании, нужно 5 символов или больше" });
      return;
    }

    const resp = await submit.call({ name, avatarImageId: null });
    await api.financeSettings.financeSettingsUpdateEducation(resp.slug, { education: true });
    await api.financeSettings.financeSettingsUpdateEducationStep(resp.slug, { educationStep: 0 });
    await Router.push(pages.company(resp!.slug).finances.education.href);
  };

  return (
    <Modal onClose={props.close} width={400}>
      <form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Новая компания</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Input autoFocus placeholder="Название компании" value={name} onChange={(e) => setName(e.target.value)} />

          {submit.error && <Error style={{ marginTop: 24 }}>{submit.error.message}</Error>}
        </Modal.Body>

        <Modal.Actions>
          <Modal.Action htmlType="button" onClick={props.close}>
            Назад
          </Modal.Action>

          <Modal.Action htmlType="submit" disabled={submit.loading}>
            {!submit.loading ? "Добавить" : <LoadingDots size={4}>Добавляем</LoadingDots>}
          </Modal.Action>
        </Modal.Actions>
      </form>
    </Modal>
  );
};

CompanyCreateModal.defaultProps = {};
