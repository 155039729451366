import React, { CSSProperties, useMemo } from "react";
import cx from "classnames";
import styles from "./avatar.module.scss";
import { ImageDto } from "shared/api";
import Image from "next/image";

export interface IAvatar {
  id: string;
  name: string;
  image?: ImageDto | null;
  size: number;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Avatar = (props: IAvatar) => {
  const style = useMemo(() => ({ ...props.style, "--avatar-size": `${props.size}px` }), [props.style, props.size]);

  if (!props.image) {
    return (
      <div className={cx(styles.avatar, props.className)} style={style} id={props.htmlId}>
        {props.name?.[0]?.toUpperCase()}
      </div>
    );
  }

  return (
    <div className={cx(styles.avatar, props.className)} style={style} id={props.htmlId}>
      <Image src={props.image.url} alt={props.name} width={props.size - 2} height={props.size - 2} />
    </div>
  );
};

Avatar.defaultProps = {
  size: 32,
};
