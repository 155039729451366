import React, { ButtonHTMLAttributes } from "react";
import cx from "classnames";
import styles from "./modal-action.module.scss";

export interface IModalAction extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> {
  // variant?: "ghost";
  // type?: "primary" | "secondary" | "success";
  // loading?: boolean;
  danger?: boolean;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

export const ModalAction = ({ htmlType, className, ...props }: IModalAction) => {
  return <button className={cx(styles.modal_action, className)} {...props} type={htmlType} />;
};

ModalAction.defaultProps = {};
