import cx from "classnames";
import { useRouter } from "next/router";
import pages from "pages/_router";
import React, { CSSProperties } from "react";
import { api } from "shared/api";
import { Avatar, Icons, MenuDivider, MenuItem, MenuLink } from "shared/ui";
import styles from "./company-list.module.scss";

export interface ICompanyList {
  value: string | undefined;

  onCreate: () => void;
  onSelect: (value: string) => void;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const CompanyList = (props: ICompanyList) => {
  const companyList = api.company.useCompanyList();
  const profile = api.userProfile.useUserProfileMe();

  const isAdmin = profile.data?.role === "ADMIN";

  const router = useRouter();
  const isAdminRoute = isAdmin && (router.asPath.startsWith("/admin/") || router.asPath === "/admin");

  return (
    <div className={cx(styles.company_list, props.className)} style={props.style} id={props.htmlId}>
      {isAdmin && (
        <>
          <MenuLink
            href={pages.admin.href}
            className={cx(styles.item)}
            suffix={isAdminRoute && <Icons.Done />}
            prefix={<Avatar id="" name="★" size={24} />}
          >
            Админка
          </MenuLink>
          <MenuDivider />
        </>
      )}

      {companyList.data?.map((it) => {
        const active = it.slug === props.value && !isAdminRoute;
        return (
          <MenuItem
            key={it.id}
            suffix={active && <Icons.Done />}
            onClick={() => props.onSelect(it.slug || it.id)}
            className={cx(styles.item, active && styles.active)}
            prefix={<Avatar id={it.id} name={it.name} image={it.avatarImage} size={24} />}
          >
            {it.name}
          </MenuItem>
        );
      })}

      <MenuItem prefix={<Icons.PlusCircle />} onClick={props.onCreate}>
        Добавить компанию
      </MenuItem>
    </div>
  );
};

CompanyList.defaultProps = {};
