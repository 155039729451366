import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./modal-body.module.scss";

export interface IModalBody {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalBody = (props: IModalBody) => {
  return (
    <div className={cx(styles.modal_body, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

ModalBody.defaultProps = {};
