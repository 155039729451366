import cx from "classnames";
import React, { CSSProperties, forwardRef } from "react";
import styles from "./container.module.scss";

export interface IContainer {
  fullWidth?: boolean;

  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Container = forwardRef<HTMLDivElement, IContainer>((props: IContainer, ref) => {
  return (
    <div
      className={cx(styles.container, props.fullWidth && styles.full_width, props.className)}
      style={props.style}
      id={props.htmlId}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

Container.defaultProps = {};
