import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./modal-title.module.scss";

export interface IModalTitle {
  children?: React.ReactNode;
  left?: boolean;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalTitle = (props: IModalTitle) => {
  return (
    <h3
      className={cx(styles.modal_title, props.left && styles.left, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      {props.children}
    </h3>
  );
};

ModalTitle.defaultProps = {};
