import cx from "classnames";
import React, { CSSProperties, useMemo } from "react";
import * as Icons from "../icon";
import styles from "./select.module.scss";

export type ISelectValue = string | number | undefined;

export interface ISelectOption<Value extends ISelectValue> {
  value: Value;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface ISelectOptionGroup<Value extends ISelectValue> {
  key: string | number;
  label: string;
  options: ISelectOption<Value>[] | undefined;
}

export type ISelect<Value extends ISelectValue> = {
  label?: React.ReactNode;
  size?: "small" | "medium" | "large";

  value?: Value;
  options?: Array<ISelectOption<Value> | ISelectOptionGroup<Value>>;

  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: React.ReactNode;

  style?: CSSProperties;
  className?: string;
  htmlId?: string;

  tabIndex?: number;
} & (
  | {
      allowEmpty?: false;
      onChange?: (value: Value) => void;
    }
  | {
      allowEmpty: true;
      onChange?: (value: Value | undefined) => void;
    }
);

const PLACEHOLDER_VALUE = "$__PLACEHOLDER__$";

export const Select = <Value extends ISelectValue>({
  value,
  onChange,
  options,
  size,
  className,
  label,
  readOnly,
  allowEmpty,
  placeholder,
  ...props
}: ISelect<Value>) => {
  const hasUndefinedOption = useMemo(
    () =>
      options
        ?.map((it) => ("options" in it ? it.options || [] : [it]))
        .flat()
        .find((it) => it.value === undefined),
    [options]
  );

  const onChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === PLACEHOLDER_VALUE) {
      if (allowEmpty) {
        onChange?.(undefined!);
      }
      return;
    }

    onChange?.(e.target.value as Value);
  };

  return (
    <label className={cx(styles.container, className)}>
      {label && <span className={styles.label}>{label}</span>}

      <select
        className={cx(
          styles.select,
          size === "small" && styles.small,
          size === "medium" && styles.medium,
          size === "large" && styles.large,
          readOnly && styles.read_only,
          value === undefined && styles.empty
        )}
        {...props}
        onChange={onChangeHandler}
      >
        {value === undefined && !hasUndefinedOption && (
          <option value={PLACEHOLDER_VALUE} selected>
            {placeholder}
          </option>
        )}
        {!!value && allowEmpty && (
          <option value={PLACEHOLDER_VALUE} selected>
            {placeholder}
          </option>
        )}

        {options?.map((it) => {
          if ("options" in it) {
            // OptionGroup
            return (
              <optgroup key={it.key} label={it.label}>
                {it.options?.map((it) => (
                  <option key={it.value} value={it.value} selected={value === it.value}>
                    {it.label}
                  </option>
                ))}
              </optgroup>
            );
          } else {
            // Option
            return (
              <option key={it.value} value={it.value} disabled={it.disabled} selected={value === it.value}>
                {it.label}
              </option>
            );
          }
        })}
      </select>

      <span className={styles.suffix}>
        <Icons.Down />
      </span>
    </label>
  );
};

Select.defaultProps = {};
