import cx from "classnames";
import { CompanyCreateModal } from "modules/company/features";
import Link from "next/link";
import { useRouter } from "next/router";
import pages from "pages/_router";
import React, { CSSProperties } from "react";
import { api } from "shared/api";
import { Modals } from "shared/lib";
import { Avatar, Icons, Menu, MenuButton, MenuContainer } from "shared/ui";
import { CompanyList } from "./company-list";
import styles from "./company-switcher.module.scss";
import { useCompanySlug } from "./lib";

export interface ICompanySwitcher {
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const CompanySwitcher = (props: ICompanySwitcher) => {
  const [slug, setSlug] = useCompanySlug();
  const { data: company } = api.company.useCompanyGet(slug!);

  const profile = api.userProfile.useUserProfileMe();

  const isAdmin = profile.data?.role === "ADMIN";

  const router = useRouter();
  const isAdminRoute = isAdmin && (router.asPath.startsWith("/admin/") || router.asPath === "/admin");

  const onCreate = () => {
    Modals.open(CompanyCreateModal, {});
  };

  return (
    <MenuContainer>
      <MenuButton
        variant="unstyled"
        className={cx(styles.company_switcher, props.className)}
        style={props.style}
        id={props.htmlId}
      >
        {!isAdminRoute && (
          <Link href={pages.company(slug!).finances.href} className={styles.company}>
            <Avatar id={company?.id!} name={company?.name!} image={company?.avatarImage!} />
            <span className={styles.name}>{company?.name}</span>
          </Link>
        )}
        {isAdminRoute && (
          <Link href={pages.admin.href} className={styles.company}>
            <Avatar id="" name="★" />
            <span className={styles.name}>Админка</span>
          </Link>
        )}
        <div className={styles.button}>
          <Icons.Switch />
        </div>
      </MenuButton>
      <Menu arrow={false}>
        <CompanyList value={slug} onCreate={onCreate} onSelect={setSlug} />
      </Menu>
    </MenuContainer>
  );
};

CompanySwitcher.defaultProps = {};
