import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./header-dashboard.module.scss";
import { api } from "shared/api";
import { Avatar, Popover, Select } from "shared/ui";
import Link from "next/link";
import pages from "pages/_router";
import { useTheme } from "modules/layout/model";
import { CompanySwitcher } from "../company-switcher";

export interface IHeaderDashboard {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const HeaderDashboard = (props: IHeaderDashboard) => {
  const profile = api.userProfile.useUserProfileMe();
  const [theme, setTheme] = useTheme();

  return (
    <nav className={cx(styles.header_dashboard, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.breadcrumbs}>
        <div className={styles.logo}>control.online</div>
        <CompanySwitcher />
      </div>

      <div className={styles.right}>
        <Popover
          trigger={
            <div className={styles.profile}>
              <span className={styles.full_name}>{profile.data?.name || profile.data?.email}</span>
              <Avatar
                id={profile.data?.id!}
                name={profile.data?.name! || profile.data?.email!}
                image={profile.data?.avatarImage!}
              />
            </div>
          }
        >
          <Popover.Menu>
            <Popover.Item>
              <Link href={pages.profile.href}>Настройки</Link>
            </Popover.Item>
            <Popover.Item>
              Тема{" "}
              <Select
                size="small"
                value={theme}
                onChange={setTheme}
                style={{ width: 128, margin: "-8px 0 -8px 10px" }}
                options={[
                  { value: undefined, label: "Системная" },
                  { value: "dark", label: "Темная" },
                  { value: "light", label: "Светлая" },
                ]}
              />
            </Popover.Item>
            <Popover.Item>
              <Link href={pages.logout.href}>Выйти</Link>
            </Popover.Item>
          </Popover.Menu>
        </Popover>
      </div>
    </nav>
  );
};

HeaderDashboard.defaultProps = {};
