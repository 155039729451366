import cx from "classnames";
import React, { CSSProperties } from "react";
import styles from "./error.module.scss";

export interface IError {
  label?: React.ReactNode;

  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Error = (props: IError) => {
  return (
    <div className={cx(styles.error, props.className)} style={props.style} id={props.htmlId}>
      {props.label !== null && props.label !== false && <b>{props.label ?? "Ошибка"}: </b>}
      {props.children}
    </div>
  );
};

Error.defaultProps = {};
