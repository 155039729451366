import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { LocalStorage } from "shared/lib/local-storage";

export const useLocalStorage = (
  key: string,
  initial?: string | (() => string | undefined)
): [string | undefined, Dispatch<SetStateAction<string | undefined>>] => {
  const [value, setValue] = useState<string | undefined>(() => LocalStorage.getItem(key));

  useEffect(() => {
    let newValue = LocalStorage.getItem(key);
    if (newValue === undefined) {
      newValue = typeof initial === "function" ? initial() : initial;
    }
    setValue(newValue);

    return LocalStorage.subscribe(key, setValue);
  }, [key, setValue]);

  const onChange: Dispatch<SetStateAction<string | undefined>> = useCallback(
    (valueOrFn) => {
      const newValue = typeof valueOrFn === "function" ? valueOrFn(LocalStorage.getItem(key)) : valueOrFn;
      LocalStorage.setItem(key, newValue);
    },
    [key]
  );

  return [value, onChange];
};
