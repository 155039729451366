import { capitalize } from "shared/lib/capitalize";

export const getErrorMessage = async (e: unknown): Promise<{ type: "custom"; message: string }> => {
  if (e instanceof Response) {
    try {
      const json = await e.json();

      if (typeof json !== "object" || typeof json.message !== "string") {
        console.error(json);
        throw new Error();
      }

      const type = typeof json.type === "string" ? json.type : "custom";
      let message = capitalize(json.message)!;

      if (!message.endsWith(".")) {
        message += ".";
      }

      return { type, message };
    } catch (ignore) {
      return { type: "custom", message: e.statusText };
    }
  } else if (e instanceof Error) {
    return { type: "custom", message: e.message };
  } else {
    return {
      type: "custom",
      message: "Неизвестная ошибка. Попробуйте еще раз, если не получится — обратитесь в поддержку.",
    };
  }
};
