import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./modal-actions.module.scss";

export interface IModalActions {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalActions = (props: IModalActions) => {
  return (
    <footer className={cx(styles.modal_actions, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </footer>
  );
};

ModalActions.defaultProps = {};
