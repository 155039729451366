import { useCallback, useState } from "react";
import { getErrorMessage } from "shared/lib/error-message";

type AbstractMethod = (...args: any) => Promise<any> | any;
type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;
type Response<Method extends AbstractMethod> = UnwrapPromise<ReturnType<Method>>;

export const useApiMethod = <Method extends AbstractMethod>(
  method: Method,
  onSuccess?: (resp: Response<Method>) => void
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<{ type: string; message: string }>();

  const call = useCallback(
    async (...args: Parameters<Method>): Promise<Response<Method>> => {
      if (loading) throw new Error("Method is loading");
      setLoading(true);

      try {
        // @ts-ignore
        const resp = await method(...args);
        setError(undefined);
        await onSuccess?.(resp);
        return resp;
      } catch (e) {
        const err = await getErrorMessage(e);
        setError(err);
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [method, setLoading, setError]
  );

  return {
    call,
    loading,
    error,
    setError,
  };
};
