import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./popover-menu.module.scss";

export interface IPopoverMenu {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const PopoverMenu = (props: IPopoverMenu) => {
  return (
    <div className={cx(styles.popover_menu, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

PopoverMenu.defaultProps = {};
