import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./popover-divider.module.scss";

export interface IPopoverDivider {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const PopoverDivider = (props: IPopoverDivider) => {
  return (
    <div className={cx(styles.popover_divider, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </div>
  );
};

PopoverDivider.defaultProps = {};
