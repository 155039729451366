import { IconChevronRight } from "@tabler/icons-react";
import cx from "classnames";
import Link from "next/link";
import React, { CSSProperties } from "react";
import { Container } from "../container";
import styles from "./header-title.module.scss";

export interface IHeaderTitle {
  back?: { title: React.ReactNode; href: string };
  children?: React.ReactNode;
  actions?: React.ReactNode;

  fullWidth?: boolean;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const HeaderTitle = (props: IHeaderTitle) => {
  return (
    <div
      className={cx(styles.header_title, props.back && styles.with_back, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      <Container fullWidth={props.fullWidth}>
        <div className={styles.inner}>
          <div className={styles.main}>
            {props.back && (
              <Link href={props.back.href} className={styles.back}>
                {props.back.title}
                <span className={styles.back_icon}>
                  <IconChevronRight stroke={1.5} size={20} />
                </span>
              </Link>
            )}
            <h1>{props.children}</h1>
          </div>

          {props.actions && <div className={styles.actions}>{props.actions}</div>}
        </div>
      </Container>
    </div>
  );
};

HeaderTitle.defaultProps = {};
