import React, { CSSProperties } from "react";
import cx from "classnames";
import styles from "./modal-subtitle.module.scss";

export interface IModalSubtitle {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalSubtitle = (props: IModalSubtitle) => {
  return (
    <p className={cx(styles.modal_subtitle, props.className)} style={props.style} id={props.htmlId}>
      {props.children}
    </p>
  );
};

ModalSubtitle.defaultProps = {};
