import cx from "classnames";
import React, { CSSProperties } from "react";
import styles from "./modal-form-item.module.scss";

export interface IModalFormItem {
  label?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalFormItem = (props: IModalFormItem) => {
  return (
    <div className={cx(styles.modal_form_item, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.label}>{props.label}</div>
      {props.children}
    </div>
  );
};

ModalFormItem.defaultProps = {};
