import Router, { useRouter } from "next/router";
import { useLocalStorage } from "shared/lib";
import { useEffect } from "react";
import { api } from "shared/api";
import pages from "pages/_router";

export const useCompanySlug = (): [string | undefined, (slug: string) => void] => {
  const companyList = api.company.useCompanyList();

  const fromUrl = useRouter().query.company as string;
  const [fromLocalStorage, saveToLocalStorage] = useLocalStorage("current-company");
  const fromApi = companyList.data?.[0]?.slug;

  const current = fromUrl || fromLocalStorage || fromApi;

  useEffect(() => {
    if (current && current !== fromLocalStorage) {
      saveToLocalStorage(current);
    }
  }, [current, fromLocalStorage, saveToLocalStorage]);

  const onChange = (slug: string) => {
    if (fromUrl) {
      Router.replace({ query: { ...Router.query, company: slug } });
      return;
    } else {
      Router.push(pages.company(slug).href);
    }
  };

  return [current, onChange];
};
