import React, { CSSProperties, useCallback, useState } from "react";
import cx from "classnames";
import styles from "./popover.module.scss";
import { PopoverMenu } from "./menu";
import { PopoverItem } from "./item";
import { PopoverDivider } from "./divider";
import { KeyName, useKeyDown } from "shared/lib";

export interface IPopover {
  trigger: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Popover = (props: IPopover) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((open) => !open), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);

  useKeyDown(KeyName.ESC, close);

  return (
    <div className={cx(styles.popover, open && styles.open, props.className)} style={props.style} id={props.htmlId}>
      <div className={styles.trigger} onClick={toggle}>
        {props.trigger}
      </div>
      <div className={cx(styles.overlay)}>
        <div className={styles.inner}>{props.children}</div>
      </div>
    </div>
  );
};

Popover.Menu = PopoverMenu;
Popover.Item = PopoverItem;
Popover.Divider = PopoverDivider;

Popover.defaultProps = {};
