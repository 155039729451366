import cx from "classnames";
import React, { CSSProperties } from "react";
import styles from "./modal-header.module.scss";

export interface IModalHeader {
  border?: boolean;

  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const ModalHeader = (props: IModalHeader) => {
  return (
    <div
      className={cx(styles.modal_header, props.border && styles.border, props.className)}
      style={props.style}
      id={props.htmlId}
    >
      {props.children}
    </div>
  );
};

ModalHeader.defaultProps = {};
